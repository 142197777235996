<template>
  <div class="bgFFF paddingLR20">
    <el-form  :model="form" :rules='rules' label-width="100px">
      <el-form-item label="配置平台"
                    prop="configPlatform">
        {{form.configPlatform==1 ? '微信' : form.configPlatform == 2 ? '支付宝' : ''}}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_Name')" prop="tenantName">
       {{form.tenantName}}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_type')" prop="tenantType">
        {{form.tenantType == 1 ? '普通商户' : form.tenantType == 2 ? '服务商' : form.tenantType == 3 ? '特约商户' : ''}}
      </el-form-item>
      <el-form-item label="父商户"  v-if="form.tenantType==3">
        <el-select v-model="form.region" placeholder="请选择商户类型">
          <el-option label="微信" value="shanghai"></el-option>
          <el-option label="支付宝" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <!--      微信-->
      <div v-show="form.configPlatform == 1">
        <h2>微信公众平台配置</h2>
        <el-form-item :label="$t('searchModule.Merchant_ID')" prop="tenantId">
          {{form.tenantId}}
        </el-form-item>
        <el-form-item label="APIkey" prop="APIkey">
          {{form.APIkey}}
        </el-form-item>
        <el-form-item label="微信公众号">
          {{form.wechartnoBound}}
        </el-form-item>
        <el-form-item label="微信小程序">
          {{form.applenoBound}}
        </el-form-item>
      </div>
      <!--      支付宝-->
      <div v-show="form.configPlatform == 2">
        <h2>支付宝商户配置</h2>
        <el-form-item label="ALIPAY_PARTNER" label-width="180px" prop="tenantId">
          {{form.tenantId}}
        </el-form-item>
        <el-form-item label="ALIPAY_PRIVATE_KEY" label-width="180px" prop="privateKey">
          {{form.privateKey}}
        </el-form-item>
        <el-form-item label="ALIPAY_ALI_PUBLIC_KEY" label-width="180px" prop="publicKey">
          {{form.publicKey}}
        </el-form-item>
        <el-form-item label="服务窗">
          <el-select v-model.trim="form.AlipayBound"
                     @change="AlipaySelect($event, 22)"
                     filterable
                     size="15">
            <el-option :label="value.applicationName"
                       :value="value.applicationId"
                       :key="value.applicationId"
                       v-for="value in AlipayBoundTenantform"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="APP-支付宝">
          <el-select v-model.trim="form.appAlipayBound"
                     @change="appAlipaySelect($event, 22)"
                     filterable
                     size="15">
            <el-option :label="value.applicationName"
                       :value="value.applicationId"
                       :key="value.applicationId"
                       v-for="value in appAlipayBoundTenantform"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "edit",
    data() {
      return {
        isEdit: false,
        form: {
          configPlatform: '',
          tenantType: '',
          APIkey: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          wechartnoBound: '',
          applenoBound: '',
          AlipayBound: '',
          appAlipayBound: '',
          tenantName: '',
          tenantId: '',
          publicKey: '',
          privateKey: '',
          ZFB_Nomal: '',
          ZFB_FuWuChuang: ''
        },
        rules: {
          configPlatform: [
            {
              required: true,
              message: '必填',
              trigger: 'blur',
            },
          ],
          tenantName: [
            {
              required: true,
              message: '必填',
              trigger: 'blur',
            },
          ],
          tenantType: [
            {
              required: true,
              message: '必填',
              trigger: 'blur',
            },
          ],
          tenantId: [
            {
              required: true,
              message: '必填',
              trigger: 'blur',
            },
          ],
          APIkey: [
            {
              required: true,
              message: '必填',
              trigger: 'blur',
            },
          ]
        },
        tenantName: '',
        tenantId: '',
        configPlatformList: [], // 平台配置
        tenantTypeList: [], // 平台配置下关联的商户类型
        applenoBoundTenantform: [], // 小程序
        wechartnoBoundTenantform: [], // 微信
        AlipayBoundTenantform: [], // 支付宝下的服务窗
        appAlipayBoundTenantform: [], // APP-支付宝
        wxnoBound: ''
      }
    },
    methods: {
      pageDetailList () {
        this.$axios.get('/acb/2.0/tenant/detail', {
          data: {
            tenantId: this.$route.query.tenantId
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.form = res.value;
            this.form.wechartnoBound = res.value.attributes.WX_Wechat
            this.form.applenoBound = res.value.attributes.WX_Applet
            this.form.APIkey = res.value.attributes.apiKey
            this.configSelect(this.form.configPlatform)
          }
        })
      },
      /* 支付商户配置相关枚举 */
      getenumsList () {
        this.$axios.get('/acb/2.0/tenant/enums').then(res => {
          if (res.state == 0) {
            this.configPlatformList = res.value.configPlatform // 平台
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        })
      },
      /* 公众号弹框里面支付宝和微信对应的下来框（没有绑定支付商户的应用列表查询 */
      wechartnoBoundTenantList () {
        this.$axios.get('/acb/2.0/application/noBoundTenantList', {
          data: {
            applicationType: 11,
            currentBoundApplicationId: ''
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.wechartnoBoundTenantform = res.value;
          }
        })
      },
      /* 小程序弹框里面支付宝和微信对应的下来框（没有绑定支付商户的应用列表查询 */
      applenoBoundTenantList () {
        this.$axios.get('/acb/2.0/application/noBoundTenantList', {
          data: {
            applicationType: 12,
            currentBoundApplicationId: ''
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.applenoBoundTenantform = res.value;
          }
        })
      },
      /* 支付宝下的服务窗（没有绑定支付商户的应用列表查询 */
      AlipaynoBoundTenantList () {
        this.$axios.get('/acb/2.0/application/noBoundTenantList', {
          data: {
            applicationType: 22,
            currentBoundApplicationId: ''
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.AlipayBoundTenantform = res.value;
          }
        })
      },
      /* APP-支付宝（没有绑定支付商户的应用列表查询 */
      appAlipaynoBoundTenantList () {
        this.$axios.get('/acb/2.0/application/noBoundTenantList', {
          data: {
            applicationType: 21,
            currentBoundApplicationId: ''
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.appAlipayBoundTenantform = res.value;
          }
        })
      },
      // 支付商户关联微信应用校验
      checkList (event, type) {
        this.$axios.post('/acb/2.0/tenant/wechat/check', {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            applicationType: type, // 应用类型
            applicationId: event // 应用id
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.applenoBoundTenantform = res.value;
          }
        })
      },
      // 支付宝应用校验
      appaletcheckList (event, type) {
        this.$axios.post('/acb/2.0/tenant/aliPay/check', {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            applicationType: type, // 应用类型
            applicationId: event // 应用id
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.applenoBoundTenantform = res.value;
          }
        })
      },
      // 服务窗和app支付宝应用校验
      AlipaycheckList (event, type) {
        this.$axios.post('/acb/2.0/tenant/aliPay/check', {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            applicationType: type, // 应用类型
            applicationId: event, // 应用id
            privateKey: this.form.privateKey,
            publicKey: this.form.publicKey
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.applenoBoundTenantform = res.value;
          }
        })
      },
      // 公众号下拉框切换
      wechartSelect(event, type) {
        this.checkList(event, type)
      },
      // 支付宝下拉框切换
      appleSelect() {

      },
      // 支付宝服务窗下拉框切换
      AlipaySelect(event, type) {
        this.ZFB_FuWuChuang = event;
        this.AlipaycheckList(event, type)
      },
      // APP-支付宝服务窗下拉框切换
      appAlipaySelect(event, type) {
        this.ZFB_Nomal = event;
        this.AlipaycheckList(event, type)
      },
      // 切换配置平台
      configSelect(code) {
        for (var i = 0; i < this.configPlatformList.length; i++) {
          if (code == this.configPlatformList[i].code) {
            this.tenantTypeList = this.configPlatformList[i].tenantTypes
          }
        }
        if (code == 1) {
          this.wechartnoBoundTenantList()
          this.applenoBoundTenantList()
        } else {
          this.appAlipaynoBoundTenantList()
          this.AlipaynoBoundTenantList()
        }
      },
      // 切换商户类型
      tenantTypeSelect() {

      },
      // 支付宝的添加和修改
      aliPay () {
        let url = this.isEdit ? '/acb/2.0/tenant/aliPay/update' : '/acb/2.0/tenant/aliPay/add'
        this.$axios.post(url, {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            privateKey: this.form.privateKey,
            publicKey: this.form.publicKey,
            ZFB_Nomal: this.ZFB_Nomal,
            ZFB_FuWuChuang: this.ZFB_FuWuChuang
          }
        }).then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.$router.go(-1);
            });
          }
        })
      },
      // 微信添加和修改
      wechart () {
        this.$refs["formPage"].validate(valid => {
          if (valid) {
            let url = this.isEdit ? '/acb/2.0/tenant/wechat/update' : '/acb/2.0/tenant/wechat/add'
            this.$axios.post(url, {
              data: {
                tenantId: this.form.tenantId, // 支付商户id
                tenantName: this.form.tenantName, // 支付商户名称
                tenantType: this.form.tenantType, // 商户类型
                apiKey: this.form.APIkey,
                parentTenantId: '',
                WX_Wechat: '',
                WX_Applet: '',
                WX_APP: ''
              }
            }).then(res => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                })
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              }
            })
          }
        })
      },
      onSubmit() {
        this.$refs["formPage"].validate(valid => {
          if (valid) {
            // 微信
            if (this.form.configPlatform == 1) {
              this.wechart()
            } else {
              // 支付宝
              this.aliPay()
            }
          }
        })
      }
    },
    created() {
      this.getenumsList()
      if (this.$route.query.state != 'add') {
        this.pageDetailList()
      }
    },
    mounted() {
      if (this.$route.query.state == 'edit') {
        this.isEdit = true
      }
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .content
    background: #ffffff;
    overflow: hidden;
    border: 1px solid #c0ccda;
    border-radius: 4px;
    padding: 20px;
  .el-input
    width 20%
</style>
