var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bgFFF paddingLR20" },
    [
      _c(
        "el-form",
        {
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "配置平台", prop: "configPlatform" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.form.configPlatform == 1
                      ? "微信"
                      : _vm.form.configPlatform == 2
                      ? "支付宝"
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("searchModule.Merchant_Name"),
                prop: "tenantName",
              },
            },
            [_vm._v(" " + _vm._s(_vm.form.tenantName) + " ")]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("searchModule.Merchant_type"),
                prop: "tenantType",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.form.tenantType == 1
                      ? "普通商户"
                      : _vm.form.tenantType == 2
                      ? "服务商"
                      : _vm.form.tenantType == 3
                      ? "特约商户"
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
          _vm.form.tenantType == 3
            ? _c(
                "el-form-item",
                { attrs: { label: "父商户" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择商户类型" },
                      model: {
                        value: _vm.form.region,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "region", $$v)
                        },
                        expression: "form.region",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "微信", value: "shanghai" },
                      }),
                      _c("el-option", {
                        attrs: { label: "支付宝", value: "beijing" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.configPlatform == 1,
                  expression: "form.configPlatform == 1",
                },
              ],
            },
            [
              _c("h2", [_vm._v("微信公众平台配置")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Merchant_ID"),
                    prop: "tenantId",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.tenantId) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APIkey", prop: "APIkey" } },
                [_vm._v(" " + _vm._s(_vm.form.APIkey) + " ")]
              ),
              _c("el-form-item", { attrs: { label: "微信公众号" } }, [
                _vm._v(" " + _vm._s(_vm.form.wechartnoBound) + " "),
              ]),
              _c("el-form-item", { attrs: { label: "微信小程序" } }, [
                _vm._v(" " + _vm._s(_vm.form.applenoBound) + " "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.configPlatform == 2,
                  expression: "form.configPlatform == 2",
                },
              ],
            },
            [
              _c("h2", [_vm._v("支付宝商户配置")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ALIPAY_PARTNER",
                    "label-width": "180px",
                    prop: "tenantId",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.tenantId) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ALIPAY_PRIVATE_KEY",
                    "label-width": "180px",
                    prop: "privateKey",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.privateKey) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ALIPAY_ALI_PUBLIC_KEY",
                    "label-width": "180px",
                    prop: "publicKey",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.publicKey) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "服务窗" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", size: "15" },
                      on: {
                        change: function ($event) {
                          return _vm.AlipaySelect($event, 22)
                        },
                      },
                      model: {
                        value: _vm.form.AlipayBound,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "AlipayBound",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.AlipayBound",
                      },
                    },
                    _vm._l(_vm.AlipayBoundTenantform, function (value) {
                      return _c("el-option", {
                        key: value.applicationId,
                        attrs: {
                          label: value.applicationName,
                          value: value.applicationId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP-支付宝" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", size: "15" },
                      on: {
                        change: function ($event) {
                          return _vm.appAlipaySelect($event, 22)
                        },
                      },
                      model: {
                        value: _vm.form.appAlipayBound,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "appAlipayBound",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.appAlipayBound",
                      },
                    },
                    _vm._l(_vm.appAlipayBoundTenantform, function (value) {
                      return _c("el-option", {
                        key: value.applicationId,
                        attrs: {
                          label: value.applicationName,
                          value: value.applicationId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }